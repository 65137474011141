<template>
  <div class="loader">
    <div class="loader__slice"></div>
    <div class="loader__slice"></div>
    <div class="loader__slice"></div>
    <div class="loader__slice"></div>
    <div class="loader__slice"></div>
    <div class="loader__slice"></div>
  </div>
</template>
